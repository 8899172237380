// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/LatencyBar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/LatencyBar.tsx");
  import.meta.hot.lastModified = "1693424179137.6946";
}
// REMIX HMR END

import { useEffect, useState } from "react";
export const LatencyBar = ({
  totalMs,
  maxValue,
  label,
  color
}) => {
  _s();
  // Maximum width of the bar, as a percentage. If this was the slower query, it's 100%
  const maxWidth = Math.round(totalMs / maxValue * 100);
  // How much we will increment per step
  const increment = 100 / maxValue;
  // the width of the bar as a percentage of its total potential width
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (width >= maxWidth) return;
    const timer = setInterval(() => {
      setWidth(width + increment);
    }, 1);
    return () => clearInterval(timer);
  }, [width]);

  // const labelOpacity = Math.round((width / maxWidth) * 100) / 100;
  const labelVisible = width >= maxWidth - 5;
  return <div className="mb-2">
      <div className="flex justify-items-start">
        <div className={`bg-${color} text-slate-50 rounded mb-2 flex justify-between items-baseline`} style={{
        width: `${width}%`,
        height: "30px"
      }}>
          <div className="text-sm p-1 ml-2">
            {totalMs}
            <span className="ml-1">ms</span>
          </div>
          <div className={`transition-opacity whitespace-nowrap overflow-hidden text-ellipsis opacity-${labelVisible ? 100 : 0} text-xs mr-2`}>
            {label}
          </div>
        </div>
      </div>
    </div>;
};
_s(LatencyBar, "4/nr7/glcmtQpNPa3y1HsyGlpKw=");
_c = LatencyBar;
export const LatencyBar2 = ({
  totalMs,
  maxValue,
  label,
  color
}) => {
  const normalized = Math.round(totalMs / maxValue * 100);
  return <div className="mb-2">
      <div className="flex justify-items-start">
        <div className={`bg-${color} rounded mb-2 flex justify-between items-center`} style={{
        width: `${normalized}%`,
        // animation: `progress ${4 * normalized}ms linear`,
        animation: `progress 700ms linear`,
        transformOrigin: "0% 50%"
      }}>
          <div className="text-sm text-slate-400 p-1 ml-2">
            {totalMs}
            <span className="ml-1">ms</span>
          </div>
          <div className="text-xs font-bold mr-2">{label}</div>
        </div>
      </div>
    </div>;
};
_c2 = LatencyBar2;
var _c, _c2;
$RefreshReg$(_c, "LatencyBar");
$RefreshReg$(_c2, "LatencyBar2");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;