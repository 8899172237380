// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/CloudflareLogo.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/CloudflareLogo.tsx");
  import.meta.hot.lastModified = "1693424179137.5237";
}
// REMIX HMR END

export const CloudflareLogo = () => {
  return <div className="w-36">
      <a href="https://www.cloudflare.com">
        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 651.29 94.76">
          <defs></defs>
          <path d="m143.05 93.42 1.07-3.71c1.27-4.41.8-8.48-1.34-11.48-2-2.76-5.26-4.38-9.25-4.57L58 72.7a1.47 1.47 0 0 1-1.35-2 2 2 0 0 1 1.75-1.34l76.26-1c9-.41 18.84-7.75 22.27-16.71l4.34-11.36a2.68 2.68 0 0 0 .18-1 3.31 3.31 0 0 0-.06-.54 49.67 49.67 0 0 0-95.49-5.14 22.35 22.35 0 0 0-35 23.42A31.73 31.73 0 0 0 .34 93.45a1.47 1.47 0 0 0 1.45 1.27h139.49a1.83 1.83 0 0 0 1.77-1.3Z" fill="#F6821F"></path>
          <path d="M168.22 41.15q-1 0-2.1.06a.88.88 0 0 0-.32.07 1.17 1.17 0 0 0-.76.8l-3 10.26c-1.28 4.41-.81 8.48 1.34 11.48a11.65 11.65 0 0 0 9.24 4.57l16.11 1a1.44 1.44 0 0 1 1.14.62 1.5 1.5 0 0 1 .17 1.37 2 2 0 0 1-1.75 1.34l-16.73 1c-9.09.42-18.88 7.75-22.31 16.7l-1.21 3.16a.9.9 0 0 0 .79 1.22h57.63a1.55 1.55 0 0 0 1.54-1.17 41.34 41.34 0 0 0-39.76-52.48Z" fill="#FBAD41"></path>
          <path className="fill:black dark:fill-white" d="M273.03 59.66h9.53v26.06h16.67v8.35h-26.2V59.66zM309.11 77v-.09c0-9.88 8-17.9 18.58-17.9s18.48 7.92 18.48 17.8v.1c0 9.88-8 17.89-18.58 17.89s-18.48-7.95-18.48-17.8m27.33 0v-.09c0-5-3.59-9.29-8.85-9.29s-8.7 4.22-8.7 9.19v.1c0 5 3.59 9.29 8.8 9.29s8.75-4.23 8.75-9.2M357.84 79V59.66h9.69v19.12c0 5 2.5 7.33 6.34 7.33s6.34-2.26 6.34-7.08V59.66h9.68v19.07c0 11.11-6.34 16-16.12 16s-15.93-5-15.93-15.73M404.49 59.66h13.27c12.29 0 19.42 7.08 19.42 17v.1c0 9.93-7.23 17.3-19.61 17.3h-13.08Zm13.42 26c5.7 0 9.49-3.15 9.49-8.71v-.09c0-5.51-3.79-8.71-9.49-8.71H414v17.47ZM451.04 59.66h27.52v8.36h-17.98v5.85h16.27v7.91h-16.27v12.29h-9.54V59.66zM491.84 59.66h9.53v26.06h16.67v8.35h-26.2V59.66zM543 59.42h9.19l14.61 34.65h-10.22l-2.51-6.14h-13.28l-2.45 6.14h-10Zm8.35 21.08-3.83-9.78-3.92 9.78ZM579.08 59.66h16.27c5.27 0 8.9 1.38 11.21 3.74a10.64 10.64 0 0 1 3.05 8v.1a10.88 10.88 0 0 1-7.08 10.57l8.21 12h-11l-6.94-10.42h-4.18v10.42h-9.54Zm15.83 16.52c3.25 0 5.12-1.58 5.12-4.08V72c0-2.71-2-4.08-5.17-4.08h-6.24v8.26ZM623.37 59.66h27.68v8.11h-18.24v5.21h16.52v7.52h-16.52v5.46h18.48v8.11h-27.92V59.66zM252.15 81a8.44 8.44 0 0 1-7.88 5.16c-5.22 0-8.8-4.33-8.8-9.29v-.1c0-5 3.49-9.2 8.7-9.2a8.64 8.64 0 0 1 8.18 5.71h10C260.79 65.09 253.6 59 244.27 59c-10.62 0-18.58 8-18.58 17.9v.1c0 9.88 7.86 17.8 18.48 17.8 9.08 0 16.18-5.88 18.05-13.76Z"></path>
        </svg>
      </a>
    </div>;
};
_c = CloudflareLogo;
var _c;
$RefreshReg$(_c, "CloudflareLogo");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;