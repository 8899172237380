// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/RunResult.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/RunResult.tsx");
  import.meta.hot.lastModified = "1693591581556.3557";
}
// REMIX HMR END

import { LatencyBar } from "./LatencyBar";
import { formatMultiplier } from "~/utils";
export const RunResult = ({
  key,
  result
}) => {
  const maxValue = Math.max(result.direct.total, result.sqc.total);
  return <div key={key}
  // className="border rounded border-zinc-400 my-2 p-3 flex flex-row justify-between"
  className="bg-zinc-200 dark:bg-zinc-900 rounded my-2 p-3 flex flex-row justify-between">
      <div className="grow">
        <LatencyBar totalMs={result.direct.total} maxValue={maxValue} label={"direct to origin database"} color="zinc-600" />
        <LatencyBar totalMs={result.sqc.total} maxValue={maxValue} label={"with Hyperdrive"} color="orange-600" />
        {formatMultiplier(result.sqc.total, result.direct.total)}

        {/* hack for fancy tailwind color optimizer */}
        <span className="bg-orange-600" />
        <span className="bg-zinc-600" />
        <span className="opacity-0" />
      </div>
    </div>;
};
_c = RunResult;
export const RunResult2 = ({
  key,
  result
}) => {
  const maxValue = Math.max(result.direct.total, result.sqc.total);
  return <div key={key} className="border border-zinc-500 dark:border-zinc-400 rounded my-2 p-3 flex flex-row justify-between">
      <div className="grow">
        <LatencyBar totalMs={result.direct.total} maxValue={maxValue} label={"direct to origin database"} color="zinc-600" />
        <LatencyBar totalMs={result.sqc.total} maxValue={maxValue} label={"with Hyperdrive"} color="orange-600" />
        {formatMultiplier(result.sqc.total, result.direct.total)}

        {/* hack for fancy tailwind color optimizer */}
        <span className="bg-orange-600" />
        <span className="bg-zinc-600" />
        <span className="opacity-0" />
      </div>
    </div>;
};
_c2 = RunResult2;
var _c, _c2;
$RefreshReg$(_c, "RunResult");
$RefreshReg$(_c2, "RunResult2");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;